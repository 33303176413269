import slick from 'jquery_version/slick.js';
// import imgLiquid from 'plugs/imgLiquid-min.js';
// import fullpage from 'fullpage.js'
// import 'svg.js';
// import 'jquery.easing.1.3.js';;
// import 'jquery.mCustomScrollbar.concat.min.js';
// import 'fullpage.extensions.min.js';
// import 'scrolloverflow.min.js';
// import 'jquery_version/jquery.validate.min.js';


$(document).ready(function () {
  $('body').addClass('loading');

  $("html,body").animate({
    scrollTop: $(this).scrollTop() - 1
  }, 10);

  /* ==========================================================================
                * ALL
    ==========================================================================*/
  var $window = $(window);
  var windowHeight = $(window).height();
  var windowWidth = $(window).width();
  var footerHeight = $('footer').height();

  /* ==========================================================================
                * 語言
    ==========================================================================*/

    // $('.lan-bx a:nth-child(2)').click(function(){
    //   $('body').addClass('lang_tw');
    //   $('body').removeClass('lang_en');
    //   $('.lan-bx a:nth-child(2)').addClass('active');
    //   $('.lan-bx a:nth-child(1)').removeClass('active');
    //   document.cookie="lang = tw";
    // })
    // $('.lan-bx a:nth-child(1)').click(function(){
    //   $('body').addClass('lang_en');
    //   $('body').removeClass('lang_tw');
    //   $('.lan-bx a:nth-child(1)').addClass('active');
    //   $('.lan-bx a:nth-child(2)').removeClass('active');
    //   document.cookie="lang = en";
    // })

    // if(getCookieByName('lang') == 'tw'){
    //   $('body').addClass('lang_tw');
    //   $('body').removeClass('lang_en');
    // } else if(getCookieByName('lang') == "en"){
    //   $('body').addClass('lang_en');
    //   $('body').removeClass('lang_tw');
    // }

    // // 讀取cookie name內的value
    // function parseCookie() {
    //   var cookieObj = {};
    //   var cookieAry = document.cookie.split(';');
    //   var cookie;
      
    //     for (var i=0, l=cookieAry.length; i<l; ++i) {
    //         cookie = jQuery.trim(cookieAry[i]);
    //         cookie = cookie.split('=');
    //         cookieObj[cookie[0]] = cookie[1];
    //     }
        
    //     return cookieObj;
    // }
    
    
    // function getCookieByName(name) {
    //     var value = parseCookie()[name];
    //     if (value) {
    //         value = decodeURIComponent(value);
    //     }
    
    //     return value;
    // }

 
  /* ==========================================================================
                * slick
    ==========================================================================*/
  $('.indexPage .awards-bx ul').slick({
    dots: false,
    arrows:true,
    infinite: true,
    speed: 500,
    cssEase: 'linear',
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive:[
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });
/* ==========================================================================
                  * resize
      ==========================================================================*/
      var windowHeight = $(window).height();
      var miniHeight = windowHeight - $('footer').outerHeight() - $('header').outerHeight();
        $('#advan-content').css({
          "min-height":miniHeight + "px"
        });
      $(window).on('resize', function(event) {
        var windowHeight = $(window).height();
        var miniHeight = windowHeight - $('footer').outerHeight() - $('header').outerHeight();
        $('#advan-content').css({
          "min-height":miniHeight + "px"
        });
        if($(this).width() > 991){
            $('body,html,footer').removeAttr("style");
            $('.header_box').removeClass('open').removeAttr('style');
            $('.nav_box').removeAttr("style");
            $('.hamburger-menu').removeClass('animate').removeAttr("style");
        }
        if($(this).width() < 992){
          $('.nav_box>nav>ul>li:nth-child(1)>a').off('click');
          $('.nav_box .under-bx .ti').off('click');
          $('.nav_box>nav>ul>li:nth-child(1)>a').on('click', function() {
            if ($(this).hasClass('open')) {
              $(this).parent().addClass('open');
              $(this).next().stop().slideUp();
              $(this).removeClass('open');
            } else {
              $(this).parent().removeClass('open');
              $(this).addClass('open');
              $(this).next().stop().slideDown();
            }
          });
          $('.nav_box .under-bx .ti').on('click', function() {
            if (!$(this).hasClass('open')) {
              $('.nav_box .under-bx .ti').removeClass('open');
              $('.nav_box .under-bx ul').stop().slideUp();
              $(this).addClass('open');
              $(this).next().stop().slideDown();
            } else {
              $(this).next().stop().slideUp();
              $(this).removeClass('open');
            }
          });
        }
        
        }).resize();


      /* ==========================================================================
                * header-menu
    ==========================================================================*/
    $('.menu-wrapper').on('click', function() {
      if (!$('.hamburger-menu').hasClass('animate')) {
          $('.hamburger-menu').addClass('animate');
          $('.hamburger-menu').removeClass('colsanimate');
          $('.nav_box').addClass('navop').stop().fadeIn();
          $('.navopbg').addClass('navbgop');
          $('.header_box').addClass('open');
          $('body,html').css({ 'overflow': 'hidden' }).addClass('ops');
          $('footer').css({ 'z-index': '-1' });
          
      } else {
          $('.hamburger-menu').addClass('colsanimate');
          $('.hamburger-menu').removeClass('animate');
          $('footer').removeAttr("style");
          $('.nav_box').removeClass('navop').stop().fadeOut();
          $('.navopbg').removeClass('navbgop');
          $('.header_box').removeClass('open');
          $('body,html,.sub_menu').removeAttr("style").removeClass('ops');
          $('.product_bottom').removeClass('active').removeAttr('style');
      }
  });






  /* ==========================================================================
                * popup
    ==========================================================================*/
    // $('.h-wlogo').click(function() {
    //   $('.pop-act').addClass('show-pop');
    //   $('body,html').css({ 'overflow': 'hidden' });
    // })

    // $('.clos-btn').click(function() {
    //   $('.pop-act').removeClass('show-pop');
    //   $('body,html,.sub_menu').removeAttr("style");
    // })
    // $('.pop-act').click(function() {
    //   var x = event.target;
    //   $(x).addClass('popherecols');
    //   $(x).removeClass('show-pop');
    //   $(x).removeClass('popherecols');
    //   $('body,html,.sub_menu').removeAttr("style");
    // })


  /* ==========================================================================
                * scrollStyle
    ==========================================================================*/
  // $('testscroll').mCustomScrollbar();


  $(window).on('scroll', function () {
    var scrollTop = $(this).scrollTop();
    var top = $('.top_btn');
    var screenHeight = $(window).height();
    var scrollTop2 = $(this).scrollTop() + (screenHeight / 2);
    if(scrollTop > 99){
      $('.header_show').addClass('chheader');
    } else{
      $('.header_show').removeClass('chheader');
    }
    if(scrollTop > 170) {
      top.addClass('show-topbtn');
    } else {
      top.removeClass('show-topbtn');
      }

      var top_number;
      if ($(this).width() >= 768) {
          top_number = 450;
      } else {
          top_number = 300;
      }
      if (scrollTop2 >= $('footer').offset().top - top_number) {
          top.addClass('fix');
      } else if (scrollTop2 <= $('footer').offset().top + 400) {
        top.removeClass('fix');
      }
  })
  /* ==========================================================================
                * 內頁active 請分散到各頁下面script
    ==========================================================================*/

    // $('.nav_box ul li:nth-child(1) a').addClass('active');
    // $('.nav_box ul li:nth-child(2) a').addClass('active');
    // $('.nav_box ul li:nth-child(3) a').addClass('active');
    // $('.nav_box ul li:nth-child(4) a').addClass('active');
    // $('.nav_box ul li:nth-child(5) a').addClass('active');
  /* ==========================================================================
                * top-btn
    ==========================================================================*/


    $(".top_btn").click(function() {
      $("html,body").animate({ scrollTop: 0 }, 1000, '');
      return false;
  });

  /* ==========================================================================
    * banner
  ==========================================================================*/

      // 先宣告  (編號值,x,x)
      //初始化
      var bannerIndex = new Array();
      var nextIndex = new Array();
      var prevIndex = new Array();
      var alllength = new Array();
      var stopSlider = new Array();

      function setSlider(id, ele){
        bannerIndex[id] = 0;
        nextIndex[id] = 0;
        prevIndex[id] = 0;
        alllength[id] = ele;
        stopSlider[id] = '';
      }

      function chbanner (id) {
      nextIndex[id] = bannerIndex[id] + 1;
      prevIndex[id] = bannerIndex[id] - 1;
      if( bannerIndex[id] == alllength[id] - 1) nextIndex[id] = 0;
      if( bannerIndex[id] == 0) prevIndex[id] = alllength[id] - 1;
      // 換圖開始
      switch(id) {
        case 1:
          $('.pc-banner .it').removeClass('chbanner').stop().fadeOut();
          $('.mo-banner .it').removeClass('chbanner').stop().fadeOut();
          $('.show-box .go-it').removeClass('show-me');
          $('.show-box .go-it').eq(bannerIndex[id]).addClass('show-me');
          $('.pc-banner .it').eq(bannerIndex[id]).addClass('chbanner').stop().fadeIn();
          $('.mo-banner .it').eq(bannerIndex[id]).addClass('chbanner').stop().fadeIn();
          break;
        case 2:
          $('.pc-banner2 .it').removeClass('chbanner').stop().fadeOut();
          $('.mo-banner2 .it').removeClass('chbanner').stop().fadeOut();
          $('.show-box2 .go-it').removeClass('show-me');
          $('.show-box2 .go-it').eq(bannerIndex[id]).addClass('show-me');
          $('.mo-show-box2 .go-it').removeClass('show-me');
          $('.mo-show-box2 .go-it').eq(bannerIndex[id]).addClass('show-me');
          $('.pc-banner2 .it').eq(bannerIndex[id]).addClass('chbanner').stop().fadeIn();
          $('.mo-banner2 .it').eq(bannerIndex[id]).addClass('chbanner').stop().fadeIn();
          break;
      }
      // 換圖結束
      bannerIndex[id] = nextIndex[id] ;
      }

      function makSlider(id , mode , type){
        id = id || 1; // Slider編號
        mode = mode || 1; // 1:一開始觸發  2:左右按鍵  3: 豆豆
        type = type || 0; // 判斷左右時 1:左 2:右 / mode 3時 為索引值
        
        clearInterval(stopSlider[id]);
        switch(mode) {
          case 2:
            if(type == 1) bannerIndex[id] = prevIndex[id];
            else bannerIndex[id] = nextIndex[id];
            break;
          case 3:
            bannerIndex[id] = type;
            break;
        }
        chbanner(id);
        stopSlider[id] = setInterval(chbanner,5000,id);
      }

      //宣告
      setSlider(1, $('.pc-banner .it').length);
      setSlider(2, $('.pc-banner2 .it').length);
      makSlider(1,1);
      makSlider(2,1);

      $('.go-it').click(function(){
        var goIndex = $(this).index();
        makSlider(1 , 3 , goIndex);
      });
      // $('.go-it').click(function(){
      //   var goIndex = $(this).index();
      //   makSlider(2 , 3 , goIndex);
      // });
      // $('.arr-box .left').click(function(){
      //   var goIndex = $(this).index();
      //   makSlider(2 , 2 , 1);
      // });
      // $('.arr-box .right').click(function(){
      //   var goIndex = $(this).index();
      //   makSlider(2 , 2 , 2);
      // });

})

